import { FC } from "react";

// Ensures the root container is always 100vw, min 100vh, and centers all children along the y-axis
const Container: FC<{}> = ({ children }) => {
  return (
    <div className="flex flex-col justify-between w-screen items-center">
      {children}
    </div>
  );
};

export default Container;
