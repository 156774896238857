import {Disclosure} from "@headlessui/react";
import Link from "next/link";
import ProfileDropdown from "./ProfileDropdown";
import classnames from "classnames";
import {XMarkIcon, Bars3Icon} from "@heroicons/react/24/outline";
import classNames from "classnames";
import {useSessionContext, useUser} from "@supabase/auth-helpers-react";
import {useRouter} from "next/router";

const HeaderLink = ({text, path}: {text: string; path: string}) => {
  return (
    <li>
      <a
        href={path}
        className="block py-2 px-2 md:px-2 sm:px-4 text-slate-400 border-0"
      >
        {text}
      </a>
    </li>
  );
};

const navigation = [
  {name: "Portfolio", href: "/dashboard", current: true},
  {name: "Cause Funds", href: "/causes", current: false},
];

const loggedOutNavigation = [
  // {name: "Get Started", href: "/welcome", current: false},
  {name: "About Us", href: "/about-us", current: false, mobile: false},
  {name: "Log In", href: "/login", current: false, mobile: true},
];

const userNavigation = [{name: "Settings", href: "/settings"}];

export default function Header({
  forceLoggedOut = false,
  onboarding = false,
  selected = 0,
}) {
  const {supabaseClient} = useSessionContext();
  const user = useUser();
  const router = useRouter();

  const nav = user ? navigation : loggedOutNavigation;

  return (
    <Disclosure
      as="nav"
      className={classNames(
        user && !forceLoggedOut && !onboarding
          ? "layout border-b border-slate-100 mt-2 mb-6 bg-white"
          : "layout mt-2 mb-10 border-slate-100 border-b"
      )}
    >
      {({open}) => (
        <>
          <div className="max-w-8xl mx-auto">
            <div
              className={classNames(
                !onboarding &&
                user &&
                !forceLoggedOut &&
                "md:grid md:grid-cols-3",
                "flex justify-between h-16"
              )}
            >
              <div className="flex-shrink-0 flex items-center">
                {/* Logo */}
                <Link href="/">
                  <a className="self-center flex flex-row">
                    <img
                      className="block h-[22px] mt-[2px] w-auto mr-3 opacity-80"
                      src="/logo/02_causeway-wordmark_SMALL.png"
                      alt="Causeway"
                    />
                  </a>
                </Link>
                <p className="px-[7px] py-[1px] mt-[7px] text-xs bg-causeway-purple bg-opacity-20 rounded-md italic cursor-default select-none">
                  beta
                </p>
              </div>
              {user && !forceLoggedOut ? (
                <div className="flex justify-center">
                  {!onboarding && (
                    <div className="hidden sm:-my-px sm:flex sm:space-x-8 justify-center">
                      {navigation.map((item, index) => (
                        <Link key={item.name} href={item.href} passHref>
                          <a
                            className={classnames(
                              index === selected
                                ? "border-slate-500 text-slate-900"
                                : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700",
                              "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        </Link>
                      ))}
                    </div>
                  )}
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:text-slate-500 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              ) : (
                <div className="w-auto sm:block" id="mobile-menu">
                  <ul className="flex flex-row mt-4 md:space-x-4 sm:mt-5 text-sm font-medium">
                    {loggedOutNavigation.map((item, index) => (
                      <div
                        key={index}
                        className={classNames(
                          !item.mobile && "hidden sm:block"
                        )}
                      >
                        <HeaderLink text={item.name} path={item.href} />
                      </div>
                    ))}
                  </ul>
                </div>
              )}
              {user && !forceLoggedOut && (
                <div className="sm:ml-6 sm:flex sm:items-center justify-end">
                  {onboarding ? (
                    <a
                      href="#"
                      className="py-2 md:px-2 sm:px-4 text-slate-400 text-sm font-medium border-0 justify-end"
                      onClick={async (e) => {
                        e.preventDefault();
                        await supabaseClient.auth.signOut();
                        router.push("/");
                      }}
                    >
                      Sign out
                    </a>
                  ) : (
                    <ProfileDropdown />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Mobile dropdown */}
          <Disclosure.Panel className="sm:hidden">
            {({close}) => (
              <>
                <div className="pt-2 pb-3 space-y-1">
                  {nav.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classnames(
                        item.current
                          ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                          : "border-transparent text-slate-600 hover:bg-slate-50 hover:border-slate-300 hover:text-slate-800",
                        "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                {user && (
                  <div className="pb-3 border-t border-slate-200">
                    <div className="mt-3 space-y-1">
                      {!onboarding &&
                        userNavigation.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block px-4 py-2 text-base font-medium text-slate-500 hover:text-slate-800 hover:bg-slate-100"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      <a
                        key="Sign Out"
                        href="#"
                        className="block px-4 py-2 text-base font-medium text-slate-500 hover:text-slate-800 hover:bg-slate-100"
                        onClick={async (e) => {
                          e.preventDefault();
                          await supabaseClient.auth.signOut();
                          router.push("/login");
                        }}
                      >
                        Sign Out
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
